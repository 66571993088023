import React from 'react';
import './Cart.css'
import SumCounter from "../ProductList/SumCounter/SumCounter";
import CartItem from "./CartItem/CartItem";

const Cart = ({ addedItems, onAdd, onRemove, closeModal }) => {

    return (
        <div className={'cart-wrapper'}>
            <a className={'cart-close-btn'} onClick={closeModal}>️</a>
            <h1 className={'cart-header'}>
                Кошик
            </h1>
            {addedItems.length>0 ? <div className={'cart-list'}>
                {addedItems.map(item => (
                    <CartItem
                        product={item}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        addedItems={addedItems}
                        className={'item'}
                        key={item.id}
                    />
                ))}
            </div> : ""}
            <SumCounter items={addedItems}/>
        </div>
    )
};

export default Cart;
