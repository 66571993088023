import React, {useEffect, useState} from 'react';
import './ProductList.css'
import ProductItem from "./ProductItem/ProductItem";
import {useTelegram} from "../../hooks/useTelegram";
import Cart from "../Cart/Cart";
import Form from "../Form/Form";
import {isValidPhoneNumber} from "react-phone-number-input";

const ProductList = ({
                         products,
                         addedItems,
                         onAdd,
                         onRemove,
                         setModalIsOpen,
                         modalIsOpen,
                         toggleModal,
                         closeModal,
                         orderIsDone,
                         setOrderIsDone
                     }) => {
    const {tg} = useTelegram();

    console.log(products)

    const [phone, setPhone] = useState('');
    const [street, setStreet] = useState('');
    const [building, setBuilding] = useState('');

    const goToCart = () => setModalIsOpen(true);
    const goToForm = () => setOrderIsDone(true);
    const goPay = () => {
        if (!isValidPhoneNumber(phone)) {
            alert("Перевірте номер телефону!")
        } else {
            alert("Pay or die!")
        }
    }

    useEffect(() => {
        if (addedItems.length > 0 && !modalIsOpen && !orderIsDone) {
            tg.MainButton.show();
            tg.MainButton.setParams({
                text: 'В кошик',
                color: "#ff8c00",
            })
            tg.onEvent('mainButtonClicked', goToCart);
            return () => {
                tg.offEvent('mainButtonClicked', goToCart);
            }
        } else if (addedItems.length > 0 && modalIsOpen && !orderIsDone) {
            const totalPrice = addedItems.reduce((a, c) => a + c.price * c.ordered, 0)
            tg.MainButton.show();
            tg.MainButton.setText(
                `Замовити на ${totalPrice} грн`
            )
            tg.onEvent('mainButtonClicked', goToForm);
            return () => {
                tg.offEvent('mainButtonClicked', goToForm);
            }
        } else if (addedItems.length > 0 && modalIsOpen && orderIsDone){
            tg.MainButton.show();
            tg.MainButton.setText(
                `Оплатити`
            )
            tg.onEvent('mainButtonClicked', goPay);
            return () => {
                tg.offEvent('mainButtonClicked', goPay);
            }
        } else {
            tg.MainButton.hide()
        }
    }, [addedItems, tg.MainButton, modalIsOpen, orderIsDone, phone]);

    return (
        <div className={'list-wrapper'}>
            {products.map((cat) => {
                return (
                    <div key={cat.cat_id} className={'list'}>
                        <h1 className={'category'} id={cat.cat_name}>
                            {cat.cat_name}
                        </h1>
                        {cat.products.map((item) => {
                            return (
                                <ProductItem
                                    product={item}
                                    onAdd={onAdd}
                                    onRemove={onRemove}
                                    addedItems={addedItems}
                                    className={'item'}
                                    key={item.id}
                                />)
                        })
                        }
                    </div>
                )
            })
            }

            {addedItems.length > 0 && modalIsOpen && !orderIsDone ? <Cart
                className={'cart'}
                addedItems={addedItems}
                onAdd={onAdd}
                onRemove={onRemove}
                modalIsOpen={modalIsOpen}
                toggleModal={toggleModal}
                closeModal={closeModal}
            /> : ""}

            {addedItems.length > 0 && modalIsOpen && orderIsDone ? <Form
                addedItems={addedItems}
                phone={phone}
                setPhone={setPhone}
                street={street}
                setStreet={setStreet}
                building={building}
                setBuilding={setBuilding}
            /> : ""}
        </div>
    );
};

export default ProductList;
