import React from 'react';
import './Header.css';
import {useTelegram} from "../../hooks/useTelegram";
import { slide as Menu } from 'react-burger-menu'

const Header = ({ allProducts, onMenuClick, toggleModal, addedItems }) => {

    const { user } = useTelegram();

    return (
        <div className={'header'}>
            <span className={'username'}>
                Привіт, <span className={'usertext'}>{user?.username}</span>
            </span>
            <span onClick={toggleModal} className={'cart-btn'}>
                🛒
                {addedItems.length > 0 ? <span className={'cart-badge'}>{addedItems.reduce((c, cur) => c + cur.ordered, 0)}</span> : ""}
            </span>
            <Menu right styles={{height: '90%'}}>
                {allProducts.map(product => {
                    return <a key={product.cat_id} href={`#${product.cat_name}`} className="menu-item" onClick={onMenuClick}>{product.cat_name}</a>
                })}
            </Menu>
        </div>
    )
}

export default Header;
