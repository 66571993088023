import './App.css';
import {useEffect, useState} from "react";
import Header from "./components/Header/Header";
import {useTelegram} from "./hooks/useTelegram";
import {Route, Routes} from 'react-router-dom';
import ProductList from "./components/ProductList/ProductList";
import Form from "./components/Form/Form";
// import products from "https://bds-studio.com/telegram/demo_3181.json";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

function App() {
    const {tg} = useTelegram();
    const [products, setProducts] = useState([])

    useEffect(() => {
        tg.ready();
    });

    useEffect( () => {
        // return axios.get('http://localhost:3000/assets/demo_3181.json',
        //     {
        //         "Access-Control-Allow-Origin": "https://bds-studio.com",
        //         'Content-Type': 'application/json',
        //     }
        // )
        //     .then((res) => {
        //         setProducts(JSON.parse(res.data));
        //     })
        async function fetchData() {
            // return await fetch("https://bds-studio.com/telegram/demo_3181.json", {
            return await fetch("./assets/demo_3181.json", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then(res => res.json())
                .then((data) => {
                    setProducts(data)
                })

        }
        fetchData()
    }, [])

    const notifyAdded = () => toast.success("Додано!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyDeleted = () => toast.error("Видалено!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });


    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [orderIsDone, setOrderIsDone] = useState(false);
    const [addedItems, setAddedItems] = useState([]);

    const toggleModal = () => {
        !modalIsOpen && addedItems.length > 0 ? setModalIsOpen(true) : setModalIsOpen(false);
    }

    const closeModal = () => {
        setModalIsOpen(false)
    }

    const onMenuClick = (event) => {
        let element = document.getElementsByClassName("bm-overlay")[0];
        element.click();
    }

    const onAdd = (product) => {
        const alreadyAdded = addedItems.find(item => item.id === product.id);
        if (alreadyAdded) {
            setAddedItems(
                addedItems.map((e) => {
                    return e.id === product.id ? {...alreadyAdded, ordered: alreadyAdded.ordered + 1} : e;
                })
            )
        } else {
            setAddedItems([...addedItems, {...product, ordered: 1}])
        }
        notifyAdded();
    }

    const onRemove = (product) => {
        const alreadyAdded = addedItems.find(item => item.id === product.id);
        if (alreadyAdded !== undefined && alreadyAdded.ordered === 1) {
            setAddedItems(
                addedItems.filter(e => e.id !== product.id)
            )
        } else {
            setAddedItems(addedItems.map(e => {
                return e.id === product.id ? {...alreadyAdded, ordered: alreadyAdded.ordered - 1} : e;
            }))
        }
        notifyDeleted();
    }

    return (
        <div className="App">
            <Header allProducts={products} onMenuClick={onMenuClick} toggleModal={toggleModal}
                    addedItems={addedItems}/>
            <ToastContainer style={{width: "160px", left: "inherit", top: "80px"}}/>
            <Routes>
                <Route index element={<ProductList
                    products={products}
                    addedItems={addedItems}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    modalIsOpen={modalIsOpen}
                    toggleModal={toggleModal}
                    closeModal={closeModal}
                    setModalIsOpen={setModalIsOpen}
                    orderIsDone={orderIsDone}
                    setOrderIsDone={setOrderIsDone}
                />}></Route>
                <Route path={'form'} element={<Form/>}></Route>
            </Routes>
        </div>
    );
}

export default App;
