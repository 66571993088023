import React, {useEffect, useState} from 'react';
import './ProductItem.css'
import Button from "../../Button/Button";

const ProductItem = ({product, className, onAdd, onRemove, addedItems}) => {

    const [counter, setCounter] = useState(0);

    useEffect(() => {
        if (addedItems.length < 1 || !addedItems.some((item) => item.name === product.name)) {
            setCounter(0)
        } else {
            addedItems.forEach(e => {
                if (product.name === e.name) {
                    setCounter(e.ordered);
                }
            })
        }
    }, [addedItems, product.name]);

    return (
        <div className={'product ' + className}>
            <div className={'img'}>
                <img src={product.foto_link} alt={product.name}/>
            </div>
            <div className={'title'}>{product.name}</div>
            <div className={'description-price-container'}>
                <div className={'description'}>{product.description}</div>
                <div className={'price-tag'}>
                    <b className={'price'}>{product.price} <br/>грн.</b>
                </div>
            </div>

            {
                counter > 0
                    ? (
                        <div className={'button-container'}>
                            <Button title={'+'} disable={false} onClick={() => onAdd(product)} type={'add'}/>
                            <Button title={'-'} disable={false} onClick={() => onRemove(product)} type={'remove'}/>
                        </div>
                    )
                    : (
                        <Button title={'Додати'} disable={false} onClick={() => onAdd(product)} type={'add'}/>
                    )
            }
            <span className={`${
                counter > 0 ? "count-badge" : "count-badge-hidden"
            }`}>{counter}</span>
        </div>
    );
};

export default ProductItem;
