import React, {useEffect, useState} from 'react';
import './CartItem.css';
import Button from "../../Button/Button";

const CartItem = ({product, addedItems, onAdd, onRemove, className}) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        addedItems.forEach(e => {
            if (product.name === e.name) {
                setCount(e.ordered);
            }
        })
    }, [addedItems, product.name]);

    return (
        <div className={'cart-product ' + className}>
            <div className={'cart-img'}>
                <img src={product.foto_link} alt={product.name}/>
            </div>
            <div className={'title'}>{product.name}
                <br/>
                <div className={'description'}>{product.description}</div>
            </div>

            <div className={'price'}>
                <span><b className={'price-tag'}>{product.price} <br/> грн.</b></span>
            </div>
            <div className={'button-container'}>
                <h1 className={'count'}>{count} шт.</h1>
                <Button title={'+'} disable={false} onClick={() => onAdd(product)} type={'add'}/>
                <Button title={'-'} disable={false} onClick={() => onRemove(product)} type={'remove'}/>
            </div>
        </div>
    );
};

export default CartItem;
