

export function useTelegram() {
    const tg = window.Telegram.WebApp;

    const closeTelegram = () => {
        tg.close();
    }

    const toggleButton = () => {
        tg.MainButton.isVisible ? tg.MainButton.hide() : tg.MainButton.show();
    }

    return {
        tg,
        closeTelegram,
        toggleButton,
        user: tg.initDataUnsafe?.user,
        queryId: tg.initDataUnsafe?.queryId
    }
}
