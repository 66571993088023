import React, {useCallback, useEffect, useState} from 'react';
import './Form.css';
import {useTelegram} from "../../hooks/useTelegram";
import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input/input';

const Form = ({
                  addedItems,
                  phone,
                  setPhone,
                  street,
                  setStreet,
                  building,
                  setBuilding,
              }) => {

    const {tg} = useTelegram();

    // const sendData = useCallback(() => {
    //     const data = {phone, street, building};
    //     tg.sendData(JSON.stringify(data))
    // }, [phone, street, building]);


    // useEffect(() => {
    //     tg.onEvent('mainButtonClicked', sendData);
    //     return () => {
    //         tg.offEvent('mainButtonClicked', sendData);
    //     }
    // }, [sendData, tg]);

    useEffect(() => {
        if (!phone || !street || !building) {
            tg.MainButton.disable();
            tg.MainButton.setParams({
                color: 'rgba(128,128,128,0.09)',
            })
        } else {
            tg.MainButton.enable();
            tg.MainButton.setParams({
                color: '#ff8c00',
            })
            // tg.onEvent('mainButtonClicked', sendData);
            // return () => {
            //     tg.offEvent('mainButtonClicked', sendData);
            // }
        }
    }, [phone, street, building])

    const onChangePhone = (e) => setPhone(e);

    const onChangeStreet = (e) => setStreet(e.target.value);

    const onChangeBuilding = (e) => setBuilding(e.target.value);

    return (
        <div className={'form'}>
            <h2>Дякуємо за Ваше замовлення!</h2>
            <br/>
            <ol>
                {addedItems.map(item => <li key={item.id}>{item.name}</li>)}
            </ol>
            <br/><br/>
            <h3>Введіть ваші дані</h3>
            <Input
                className={'input'}
                placeholder={'Телефон...'}
                value={phone}
                onChange={onChangePhone}
                country="UA"
                international
                withCountryCallingCode
            />

            <input
                type="text"
                className={'input'}
                placeholder={'Вулиця...'}
                value={street}
                onChange={onChangeStreet}
            />
            <input
                type="text"
                className={'input'}
                placeholder={'Номер будівлі, квартири...'}
                value={building}
                onChange={onChangeBuilding}
            />
        </div>
    );
};

export default Form;
