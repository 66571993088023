import React from 'react';
import './SumCounter.css'

function SumCounter({items}) {

    const totalPrice = items.reduce((a, c) => a + c.price * c.ordered, 0)

    return (
        <div className={"sum"}>
            {items.length < 1 ? <span>Кошик пустий</span> : <span>Сума: {totalPrice} грн.</span>}
        </div>
    );
}

export default SumCounter;
